import React from "react";

import Icon from "../Icon/Icon";
import {ITEMS} from "./variables";
import {sendMessage} from "../../../usecases/telegram";
import {isMobile} from "../../../utils";

const NAMES = ITEMS.map((item) => item.name);

export default class ContactsSection extends React.Component<{items?: string[]}, {}> {
  // TODO remove next update
  submit = async () => {
    try {
      await sendMessage("", "", `Клик по номеру. ContactsSection. ${isMobile() ? "Мобила" : "Десктоп"}.`);
    } catch (e) {
      // TODO
    }
  };

  renderText = (name: string, text: string) => {
    if (name === "tel") {
      return (
        <a href={`tel:+${text.replace(/\D/g, "")}`} onClick={() => this.submit()}>
          {text}
        </a>
      );
    } else if (name === "mail") {
      return <a href={`mailto:${text}`}>{text}</a>;
    } else {
      return text;
    }
  };

  renderItems = () => {
    return ITEMS.map((item, i) => {
      let names = this.props.items ? this.props.items : NAMES;

      if (names.includes(item.name)) {
        return (
          <div key={i} className="contactsSection-item">
            <div className="contactsSection-title">
              <Icon name={item.icon} title={item.iconTitle} />
              <span>{item.title}:</span>
            </div>
            <div className="contactsSection-text">{this.renderText(item.name, item.text)}</div>
          </div>
        );
      }
      return null;
    });
  };

  render() {
    return <div className="contactsSection">{this.renderItems()}</div>;
  }
}
