import React from "react";
import {Helmet} from "react-helmet-async";

import {TProps} from "./types";

export default class Seo extends React.Component<TProps, {}> {
  render() {
    const {title, description, canonical, children} = this.props;

    return (
      <Helmet prioritizeSeoTags>
        {/* Standard metadata tags */}
        <title>{title}</title>
        {description ? <meta name="description" content={description} /> : null}
        {canonical === undefined ? null : <link rel="canonical" href={`https://mebelomsk55.ru${canonical}`} />}
        {children}
      </Helmet>
    );
  }
}
