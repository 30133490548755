import {TCase} from "../../components/Case/types";

import office_1_1 from "../../../assets/images/cases/office-1-1.png";
import office_1_2 from "../../../assets/images/cases/office-1-2.png";
import office_1_3 from "../../../assets/images/cases/office-1-3.png";

export const META: {[index: string]: string} = {
  title: "Мебель для офиса в Омске недорого на заказ по индивидуальным размерам",
  description:
    "Офисная мебель на заказ – это ключ к созданию комфортного и функционального рабочего пространства. Индивидуальный дизайн позволяет учесть все ваши потребности: от эргономичных столов до стильных шкафов. Выбирайте материалы и цвета, чтобы вдохновлять сотрудников и повышать продуктивность!"
};

export const H1: string = "Мебель для офиса на заказ в Омске";

export const REVIEWS_INDEXES: Array<number> = [0, 1, 2];

export const FAQ_INDEXES: Array<number> = [2];

export const CASES: Array<TCase> = [
  {
    images: [
      {src: office_1_1, alt: "Мебель для офиса 1-1"},
      {src: office_1_2, alt: "Мебель для офиса 1-2"},
      {src: office_1_3, alt: "Мебель для офиса 1-3"}
    ],
    title: "Гардеробная для офиса",
    description: "<b>Длинна:</b> 3.7м<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "50000"
  }
];
