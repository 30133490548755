import React from "react";

import {REVIEWS_SERVICES} from "../../variables/REVIEWS_SERVICES";
import Icon from "./Icon/Icon";

export default class Review extends React.Component<{author: string; text: string; service: string}, {}> {
  renderRating = () => {
    return [0, 1, 2, 3].map((item, i) => {
      return <Icon key={i} name="starFill" title="starFill" />;
    });
  };

  render() {
    const {author, text, service} = this.props;

    return (
      <div className="review">
        <div className="review-serviceLogo">
          <img alt={`Отзывы на ${REVIEWS_SERVICES[service].name}`} src={REVIEWS_SERVICES[service].logo} />
        </div>
        <div className="review-rating">
          {this.renderRating()}
          <Icon name="starHalf" title="starHalf" />
          <span className="review-ratingCount">{REVIEWS_SERVICES[service].rating}</span>
        </div>
        <div className="review-author">{author}</div>
        <div dangerouslySetInnerHTML={{__html: text}} className="review-text" />
        {/*<div className="review-serviceLink">*/}
        {/*  Больше отзывов на{" "}*/}
        {/*  <a href={REVIEWS_SERVICES[service].link} rel="nofollow noopener noreferrer" target="_blank">*/}
        {/*    {REVIEWS_SERVICES[service].name}*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>
    );
  }
}
