import React from "react";
import {Link} from "react-router-dom";

export default class Footer extends React.Component<{}, {}> {
  render() {
    return (
      <div className="footer">
        <Link to="/privacy/" rel="nofollow" target="_blank">
          Политика в отношении обработки персональных данных
        </Link>
        <div className="footer-year">2025</div>
        <div className="footer-public">Не является публичной офертой</div>
      </div>
    );
  }
}
