import {TCase} from "../../components/Case/types";

import walkInCloset_1_1 from "../../../assets/images/cases/walk-in-closet-1-1.png";
import walkInCloset_2_1 from "../../../assets/images/cases/walk-in-closet-2-1.png";
import walkInCloset_3_1 from "../../../assets/images/cases/walk-in-closet-3-1.png";
import walkInCloset_4_1 from "../../../assets/images/cases/walk-in-closet-4-1.png";
import walkInCloset_5_1 from "../../../assets/images/cases/walk-in-closet-5-1.png";
import walkInCloset_6_1 from "../../../assets/images/cases/walk-in-closet-6-1.png";
import walkInCloset_7_1 from "../../../assets/images/cases/walk-in-closet-7-1.png";
import walkInCloset_8_1 from "../../../assets/images/cases/walk-in-closet-8-1.png";
import walkInCloset_9_1 from "../../../assets/images/cases/walk-in-closet-9-1.png";
import walkInCloset_10_1 from "../../../assets/images/cases/walk-in-closet-10-1.png";
import walkInCloset_11_1 from "../../../assets/images/cases/walk-in-closet-11-1.png";
import walkInCloset_12_1 from "../../../assets/images/cases/walk-in-closet-12-1.png";

export const META: {[index: string]: string} = {
  title: "Гардеробная в Омске недорого на заказ по индивидуальным размерам",
  description:
    "Гардеробные на заказ – это отличный способ создать идеальное пространство для хранения вещей. Индивидуальный подход позволяет выбрать размеры, материалы и дизайн, соответствующие вашему стилю и потребностям. Наслаждайтесь порядком и эстетикой в вашем доме!"
};

export const H1: string = "Гардеробная на заказ в Омске";

export const REVIEWS_INDEXES: Array<number> = [0, 1, 2];

export const FAQ_INDEXES: Array<number> = [2];

export const CASES: Array<TCase> = [
  {
    images: [{src: walkInCloset_1_1, alt: "Гардеробная 1-1"}],
    title: "Гардеробная из кладовки",
    description: "",
    price: "100 000"
  },
  {
    images: [{src: walkInCloset_2_1, alt: "Гардеробная 2-1"}],
    title: "Гардеробная из кладовки",
    description: "",
    price: "100 000"
  },
  {
    images: [{src: walkInCloset_3_1, alt: "Гардеробная 3-1"}],
    title: "Гардеробная в частном доме",
    description: "",
    price: "125 000"
  },
  {
    images: [{src: walkInCloset_4_1, alt: "Гардеробная 4-1"}],
    title: "П-образная гардеробная в частном доме",
    description: "",
    price: "114 000"
  },
  {
    images: [{src: walkInCloset_5_1, alt: "Гардеробная 5-1"}],
    title: "Гардеробная",
    description: "",
    price: "105 000"
  },
  {
    images: [{src: walkInCloset_6_1, alt: "Гардеробная 6-1"}],
    title: "Гардеробная",
    description: "",
    price: "105 000"
  },
  {
    images: [{src: walkInCloset_7_1, alt: "Гардеробная 7-1"}],
    title: "Гардеробная",
    description: "",
    price: "109 000"
  },
  {
    images: [{src: walkInCloset_8_1, alt: "Гардеробная 8-1"}],
    title: "Гардеробная в спальне",
    description: "",
    price: "100 000"
  },
  {
    images: [{src: walkInCloset_9_1, alt: "Гардеробная 9-1"}],
    title: "Гардеробная в частном доме",
    description: "",
    price: "110 000"
  },
  {
    images: [{src: walkInCloset_10_1, alt: "Гардеробная 10-1"}],
    title: "Гардеробная в частном доме",
    description: "",
    price: "100 000"
  },
  {
    images: [{src: walkInCloset_11_1, alt: "Гардеробная 11-1"}],
    title: "Гардеробная в спальне",
    description: "",
    price: "105 000"
  },
  {
    images: [{src: walkInCloset_12_1, alt: "Гардеробная 12-1"}],
    title: "Гардеробная в спальне",
    description: "",
    price: "100 000"
  }
];
