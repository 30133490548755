import React from "react";
import {createBrowserRouter} from "react-router-dom";

import {AppLayout} from "./AppLayout";
import Main from "./app/pages/Main/Main";
import Contacts from "./app/pages/Contacts/Contacts";
import Kitchens from "./app/pages/Kitchens/Kitchens";
import Closets from "./app/pages/Closets/Closets";
import WalkInClosets from "./app/pages/WalkInClosets/WalkInClosets";
import Office from "./app/pages/Office/Office";
import NotFound from "./app/pages/NotFound";
import Privacy from "./app/pages/Privacy";

export const ROUTER = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {path: "/", element: <Main />},
      {path: "/kontakty/", element: <Contacts canonical="/kontakty/" />},
      {path: "/kuhni/", element: <Kitchens canonical="/kuhni/" />},
      {path: "/shkafy-kupe/", element: <Closets canonical="/shkafy-kupe/" />},
      {path: "/garderobnye/", element: <WalkInClosets canonical="/garderobnye/" />},
      {path: "/ofisnaya-mebel/", element: <Office canonical="/ofisnaya-mebel/" />},
      {path: "/privacy/", element: <Privacy canonical="/privacy/" />},
      {path: "*", element: <NotFound />}
    ]
  }
]);
