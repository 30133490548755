import {TCase} from "../../components/Case/types";
import {TBlock, TForm} from "../../components/CalculationSection/types";
import closet_1_1 from "../../../assets/images/cases/closet-1-1.png";
import closet_1_2 from "../../../assets/images/cases/closet-1-2.png";
import closet_1_3 from "../../../assets/images/cases/closet-1-3.png";
import closet_2_1 from "../../../assets/images/cases/closet-2-1.png";
import closet_2_2 from "../../../assets/images/cases/closet-2-2.png";
import closet_3_1 from "../../../assets/images/cases/closet-3-1.png";
import closet_3_2 from "../../../assets/images/cases/closet-3-2.png";
import closet_4_1 from "../../../assets/images/cases/closet-4-1.png";
import closet_5_1 from "../../../assets/images/cases/closet-5-1.png";
import closet_6_1 from "../../../assets/images/cases/closet-6-1.png";
import closet_7_1 from "../../../assets/images/cases/closet-7-1.png";
import closet_8_1 from "../../../assets/images/cases/closet-8-1.png";
import closet_9_1 from "../../../assets/images/cases/closet-9-1.png";
import closet_10_1 from "../../../assets/images/cases/closet-10-1.png";
import closet_11_1 from "../../../assets/images/cases/closet-11-1.png";
import closet_12_1 from "../../../assets/images/cases/closet-12-1.png";
import closet_13_1 from "../../../assets/images/cases/closet-13-1.png";
import closet_14_1 from "../../../assets/images/cases/closet-14-1.png";
import closet_15_1 from "../../../assets/images/cases/closet-15-1.png";
import closet_16_1 from "../../../assets/images/cases/closet-16-1.png";
import closet_17_1 from "../../../assets/images/cases/closet-17-1.png";

export const META: {[index: string]: string} = {
  title: "Шкаф-купе в Омске недорого на заказ по индивидуальным размерам",
  description:
    "Ваш шкаф-купе почти готов, осталось лишь сделать замеры и выбрать наполнение. Опытный дизайнер покажет, расскажет и поможет сделать выбор комбинаций дверей шкафа-купе: зеркало, крашенное стекло, лакобель, пескоструй, лдсп, мдф."
};

export const H1: string = "Шкафы-купе на заказ в Омске";

export const REVIEWS_INDEXES: Array<number> = [0, 1, 2];

export const FAQ_INDEXES: Array<number> = [1, 2, 6];

export const CASES: Array<TCase> = [
  {
    images: [
      {src: closet_1_1, alt: "Шкаф-купе 1-1"},
      {src: closet_1_2, alt: "Шкаф-купе 1-2"},
      {src: closet_1_3, alt: "Шкаф-купе 1-3"}
    ],
    title: "Корпусный шкаф-купе",
    description:
      "<b>Ширина:</b> 2.1м<br /><b>Профиль:</b> алюминиевый профиль симметрия матовое серебро<br /><b>Двери:</b> лдсп<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "63 000"
  },
  {
    images: [
      {src: closet_2_1, alt: "Шкаф-купе 2-1"},
      {src: closet_2_2, alt: "Шкаф-купе 2-2"}
    ],
    title: "Встроенный шкаф-купе на всю стену",
    description:
      "<b>Ширина:</b> 5.2м<br /><b>Профиль:</b> алюминиевый профиль симметрия матовая шампань<br /><b>Двери:</b> лдсп двери с разделителем<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "182 000"
  },
  {
    images: [
      {src: closet_3_1, alt: "Шкаф-купе 3-1"},
      {src: closet_3_2, alt: "Шкаф-купе 3-2"}
    ],
    title: "Встроенный шкаф-купе",
    description:
      "<b>Ширина:</b> 3.4м<br /><b>Профиль:</b> алюминиевый профиль асимметрия матовая шампань<br /><b>Двери:</b> лдсп двери с разделителем, зеркальные двери<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "105 000"
  },
  {
    images: [{src: closet_4_1, alt: "Шкаф-купе 4-1"}],
    title: "Встроенный шкаф-купе",
    description:
      "<b>Ширина:</b> 2.7м<br /><b>Профиль:</b> алюминиевый профиль симметрия матовое серебро<br /><b>Двери:</b> лдсп двери<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "81 000"
  },
  {
    images: [{src: closet_5_1, alt: "Шкаф-купе 5-1"}],
    title: "Угловой корпусный шкаф-купе",
    description:
      "<b>Ширина:</b> 3.5м<br /><b>Профиль:</b> алюминиевый профиль асимметрия матовое серебро<br /><b>Двери:</b> лдсп двери с разделителем, зеркальные двери<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "110 000"
  },
  {
    images: [{src: closet_6_1, alt: "Шкаф-купе 6-1"}],
    title: "Полувстроенный шкаф-купе",
    description:
      "<b>Ширина:</b> 2.6м<br /><b>Профиль:</b> алюминиевый профиль симметрия матовая шампань<br /><b>Двери:</b> лдсп двери с разделителем<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "85 000"
  },
  {
    images: [{src: closet_7_1, alt: "Шкаф-купе 7-1"}],
    title: "Встроенный шкаф-купе в спальню",
    description:
      "<b>Ширина:</b> 1.8м<br /><b>Профиль:</b> алюминиевый профиль асимметрия матовое серебро<br /><b>Двери:</b> лдсп<br /><b>Наполнение:</b> штанга для вешалок, полки из лдсп",
    price: "54 000"
  },
  {
    images: [{src: closet_8_1, alt: "Шкаф-купе 8-1"}],
    title: "Встроенный шкаф-купе",
    description:
      "<b>Ширина:</b> 2.9м<br /><b>Профиль:</b> алюминиевый профиль асимметрия матовое серебро<br /><b>Двери:</b> лдсп двери с разделителем<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "87 000"
  },
  {
    images: [{src: closet_9_1, alt: "Шкаф-купе 9-1"}],
    title: "Полувстроенный шкаф-купе в спальню",
    description:
      "<b>Ширина:</b> 1.9м<br /><b>Профиль:</b> алюминиевый профиль асимметрия матовое серебро<br /><b>Двери:</b> лдсп дверь с разделителем, зеркальная дверь<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "62 000"
  },
  {
    images: [{src: closet_10_1, alt: "Шкаф-купе 10-1"}],
    title: "Полувстроенный шкаф-купе",
    description:
      "<b>Ширина:</b> 2.7м<br /><b>Профиль:</b> алюминиевый профиль симметрия матовая шампань<br /><b>Двери:</b> окрашенное стекло (Лакобель) с разделителем, зеркальная дверь<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "105 000"
  },
  {
    images: [{src: closet_11_1, alt: "Шкаф-купе 11-1"}],
    title: "Полувстроенный шкаф-купе в прихожую",
    description:
      "<b>Ширина:</b> 2.9м<br /><b>Профиль:</b> алюминиевый профиль асимметрия черный матовый<br /><b>Двери:</b> лдсп с разделителем<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "98 000"
  },
  {
    images: [{src: closet_12_1, alt: "Шкаф-купе 12-1"}],
    title: "Встроенный шкаф-купе в прихожую",
    description:
      "<b>Ширина:</b> 2.8м<br /><b>Профиль:</b> алюминиевый профиль симметрия матовое серебро<br /><b>Двери:</b> окрашенное стекло (Лакобель)<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "100 000"
  },
  {
    images: [{src: closet_13_1, alt: "Шкаф-купе 13-1"}],
    title: "Встроенный шкаф-купе в спалью",
    description:
      "<b>Ширина:</b> 2м<br /><b>Профиль:</b> алюминиевый профиль симметрия матовая шампань<br /><b>Двери:</b> окрашенное стекло (Лакобель) с разделителем<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "80 000"
  },
  {
    images: [{src: closet_14_1, alt: "Шкаф-купе 14-1"}],
    title: "Встроенный шкаф-купе на всю стену в спальню",
    description:
      "<b>Ширина:</b> 3.2м<br /><b>Профиль:</b> алюминиевый профиль асимметрия матовое серебро<br /><b>Двери:</b> комбинированные с разделителем, зеркало и лдсп<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "110 000"
  },
  {
    images: [{src: closet_15_1, alt: "Шкаф-купе 15-1"}],
    title: "Встроенный шкаф-купе",
    description:
      "<b>Ширина:</b> 2.2м<br /><b>Профиль:</b> алюминиевый профиль симметрия матовое серебро<br /><b>Двери:</b> лдсп с разделителем<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "60 000"
  },
  {
    images: [{src: closet_16_1, alt: "Шкаф-купе 16-1"}],
    title: "Корпусный шкаф-купе в спальню",
    description:
      "<b>Ширина:</b> 2.2м<br /><b>Профиль:</b> алюминиевый профиль асимметрия матовое серебро<br /><b>Двери:</b> лдсп двери с разделителем<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "75 000"
  },
  {
    images: [{src: closet_17_1, alt: "Шкаф-купе 17-1"}],
    title: "Встроенный шкаф-купе",
    description:
      "<b>Ширина:</b> 2.2м<br /><b>Профиль:</b> алюминиевый профиль симметрия матовое серебро<br /><b>Двери:</b> лдсп двери с разделителем<br /><b>Наполнение:</b> штанга для вешалок, полки и ящики из лдсп",
    price: "70 000"
  }
];

export const calculationStr = (obj: TForm) => {
  const {doors, doorsMaterials, innerMaterials, shape, type, width, height, depth} = obj;
  let t = `${type.charAt(0).toUpperCase()}${type.slice(1)}`;
  let d = `дверей ${doors}`;
  let dM = `Материал дверей: ${doorsMaterials.replace("+", "%2B")}`;
  let iM = `Внутреннее наполнение: ${innerMaterials.length ? innerMaterials.toString().replace(/,/g, ", ") : "не выбрано"}`;
  let dim = `Размеры в сантиметрах: ширина ${width}, высота ${height}, глубина ${depth}`;
  let f = "Просчитать шкаф-купе";

  return `%3Cb%3E${f}%3C%2Fb%3E%0A${t}, ${shape}, ${d}. ${dM}. ${iM}. ${dim}.`;
};

export const CALCULATION_CONFIG: TBlock[] = [
  {
    title: "Выберите тип",
    valueContainer: "string",
    disabledCondition: "type",
    items: [
      {type: "checkButton", value: "корпусный", valueName: "type", text: "Корпусный"},
      {type: "checkButton", value: "встроенный", valueName: "type", text: "Встроенный"}
    ]
  },
  {
    title: "Выберите форму",
    valueContainer: "string",
    disabledCondition: "shape",
    items: [
      {type: "checkButton", value: "прямой", valueName: "shape", text: "Прямой"},
      {type: "checkButton", value: "угловой", valueName: "shape", text: "Угловой"}
    ]
  },
  {
    title: "Выберите количество дверей",
    valueContainer: "number",
    disabledCondition: "doors",
    items: [
      {type: "checkButton", value: 2, valueName: "doors", text: "2 двери"},
      {type: "checkButton", value: 3, valueName: "doors", text: "3 двери"},
      {type: "checkButton", value: 4, valueName: "doors", text: "4 двери"},
      {type: "checkButton", value: 5, valueName: "doors", text: "5 дверей"}
    ]
  },
  {
    title: "Выберите наполнение дверей",
    valueContainer: "string",
    disabledCondition: "doorsMaterials",
    items: [
      {type: "checkButton", value: "лдсп", valueName: "doorsMaterials", text: "ЛДСП"},
      {type: "checkButton", value: "зеркало", valueName: "doorsMaterials", text: "Зеркало"},
      {type: "checkButton", value: "окрашенное стекло", valueName: "doorsMaterials", text: "Окрашенное стекло"},
      {type: "checkButton", value: "лдсп + зеркало", valueName: "doorsMaterials", text: "ЛДСП + Зеркало"},
      {
        type: "checkButton",
        value: "лдсп + окрашенное стекло",
        valueName: "doorsMaterials",
        text: "ЛДСП + Окрашенное стекло"
      },
      {
        type: "checkButton",
        value: "окрашенное стекло + зеркало",
        valueName: "doorsMaterials",
        text: "Окрашенное стекло + Зеркало"
      }
    ]
  },
  {
    title: "Выберите внутреннее наполнение",
    valueContainer: "array",
    disabledCondition: null,
    items: [
      {type: "checkButton", value: "полки", valueName: "innerMaterials", text: "Полки"},
      {type: "checkButton", value: "выдвижные ящики", valueName: "innerMaterials", text: "Выдвижные ящики"},
      {type: "checkButton", value: "штанги для вешалок", valueName: "innerMaterials", text: "Штанги для вешалок"},
      {
        type: "checkButton",
        value: "выдвижные сетчатые корзины",
        valueName: "innerMaterials",
        text: "Выдвижные сетчатые корзины"
      }
    ]
  },
  {
    title: "Укажите размеры в сантиметрах",
    valueContainer: "string",
    disabledCondition: ["width", "height", "depth"],
    items: [
      {type: "textField", valueName: "width", text: "Ширина, см"},
      {type: "textField", valueName: "height", text: "Высота, см"},
      {type: "textField", valueName: "depth", text: "Глубина, см"}
    ]
  },
  {
    title: "Укажите номер телефона",
    valueContainer: "string",
    items: [{type: "textField", valueName: "tel", text: "Номер телефона"}]
  },
  {
    title: "Спасибо за оставленную заявку на расчет",
    items: [
      {
        type: "text",
        text: "Расчет цены будет готов в самое ближайшее время. Мы сообщим стоимость вашего будущего шкафа-купе по указанному номеру телефона."
      }
    ]
  }
];
