import React from "react";
import {Link} from "react-router-dom";

import Icon from "../../../components/Icon/Icon";
import {CONTACTS} from "../../../../variables/CONTACTS";
import {LINKS} from "../variables";
import {sendMessage} from "../../../../usecases/telegram";
import {isMobile} from "../../../../utils";

export default class HeaderDropdown extends React.Component<{currentPathname: string}, {isVisible: boolean}> {
  constructor(props: {currentPathname: string}) {
    super(props);
    this.state = {
      isVisible: false
    };
  }

  close = () => {
    this.setState({isVisible: false});
  };

  show = () => {
    this.setState({isVisible: true});
  };

  // TODO remove next update
  submit = async () => {
    try {
      await sendMessage("", "", `Клик по номеру. HeaderDropdown. ${isMobile() ? "Мобила" : "Десктоп"}.`);
    } catch (e) {
      // TODO
    }
  };

  fillNav = () => {
    return [0, 1, 2, 3, 4].map((item) => {
      return <div key={item}>{this.renderLinks(item)}</div>;
    });
  };

  renderLinks = (b: number) => {
    return LINKS.map((item, i) => {
      if ((i - b) % 5 === 0) {
        return (
          <React.Fragment key={i}>
            {this.props.currentPathname === item.url ? (
              <span>{item.text}</span>
            ) : (
              <Link
                to={item.url}
                onClick={(e) => {
                  e.stopPropagation();
                  this.close();
                }}
              >
                {item.text}
              </Link>
            )}
          </React.Fragment>
        );
      }
    });
  };

  render() {
    return (
      <div className={`headerDropdown ${this.state.isVisible ? "headerDropdown--visible" : ""}`}>
        <div className="headerDropdown-contacts">
          <div className="headerDropdown-contactsItem">
            <Icon name="location" title="Адрес" />
            {CONTACTS.address}
          </div>
          <div className="headerDropdown-contactsItem">
            <Icon name="clock" title="Время работы" />
            {`${CONTACTS.week} ${CONTACTS.time}`}
          </div>
          <div className="headerDropdown-contactsItem">
            <Icon name="phone" title="Телефон" />
            <a href={`tel:+${CONTACTS.phone.replace(/\D/g, "")}`} onClick={() => this.submit()}>
              {CONTACTS.phone}
            </a>
          </div>
        </div>
        <nav className="headerDropdown-links">{this.fillNav()}</nav>
      </div>
    );
  }
}
