export const FAQ: {question: string; answer: string}[] = [
  {
    question: "Сколько по времени занимает изготовление кухонного гарнитура?",
    answer: "21 рабочий день с момента предоплаты."
  },
  {
    question: "Сколько по времени занимает изготовление шкафа-купе?",
    answer: "15 рабочих дней с момента предоплаты."
  },
  {
    question: "Возможно ли установить фурнитуру которую видели где-то на картинке?",
    answer:
      "Установим любую фурнитуру какую пожелаете. Если потребуется, закажем фурнитуру из другого города или страны."
  },
  {
    question: "С какими материалами работаете?",
    answer:
      "Корпус мебели: лдсп (Югра, Сыктывкар, EGGER). Фасады для кухни: мдф покрытая пвх пленкой, высокоглянцевые акриловые панели, крашенное мдф, рамочные из алюминиевого профиля, фасады из массива дерева."
  },
  {
    question: "",
    answer: ""
  },
  {
    question: "От чего зависит цена кухонного гарнитура?",
    answer: "Не считая фурнитуры, основная стоимость складывается из фасадов и столешницы."
  },
  {
    question: "От чего зависит цена шкафа-купе?",
    answer: "Материал наполнения дверей и наполнение внутренностей шкафа-купе."
  }
];
